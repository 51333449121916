
























































































































































































































































































































































































































































































































































































































































































































































































































































































































.homeList {
	padding-bottom: calc(env(safe-area-inset-top) + 1.5rem);
	.item_head {
		padding: 0.31rem 0 0.2rem 0;
		display: flex;
		font-family: PingFangSC-Medium;
		justify-content: space-between;
		align-items: center;
		// flex: 1;
		> div {
			padding: 2px 5px;
			background-color: #f3f8fe;
			border-radius: 4px;
		}

		.name {
			// flex: 1.6;
			// width: 30%;
			padding: 0.06rem 0.15rem;
			display: flex;
			align-items: center;
			background-color: #f4f4f4;
			justify-content: center;
			span {
				font-size: 0.24rem;
				color: #6f7989;
				border-radius: 0.04rem;
				font-weight: 600;
			}
		}

		.bucang {
			// flex: 1;
			// width: 1.16rem;
			margin-left: 0.4rem;
			padding: 0.06rem 0.15rem;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #f4f4f4;
			// margin-left: 0.5rem;
			span {
				font-size: 0.24rem;
				color: #6f7989;
				border-radius: 0.04rem;
				font-weight: 600;
				// margin-left: 0.3rem;
			}
		}
		.fuyin {
			// width: 1.16rem;
			padding: 0.06rem 0.15rem;
			display: flex;
			align-items: center;
			background-color: #f4f4f4;
			justify-content: center;
			span {
				font-size: 0.24rem;
				color: #6f7989;
				border-radius: 0.04rem;
				font-weight: 600;
				// margin-left: 0.3rem;
			}
		}

		.fudu {
			// flex: 1;
			// width: 30%;
			// width: 1.16rem;
			// padding: 0.06rem 0;
			padding: 0.06rem 0.15rem;
			display: flex;
			align-items: center;
			background-color: #f4f4f4;
			justify-content: center;
			span {
				font-size: 0.24rem;
				color: #6f7989;
				border-radius: 0.04rem;
				font-weight: 600;
				// margin-left: 0.3rem;
			}
			// .du {
			//     padding-right: 0.15rem;
			// }
		}
	}

	.listItem {
		// position: relative;
		padding: 0.2rem 0 0.2rem 0;
		border-bottom: 0.01rem solid #d3d9de;
		.list_top {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.list_bottom {
			margin-top: 0.1rem;
			display: flex;
			align-items: center;
			.dian {
				margin-top: 0.02rem;
				width: 0.08rem;
				height: 0.08rem;
				background-color: #f5465d;
				border-radius: 50%;
				// margin-right: 0.1rem;
			}
			.err_msg {
				color: #f5465d;
				font-size: 0.24rem;
			}
		}
		.listTitle {
			// margin-top: 0.4rem;
			// flex: 1.6;
			// width: 38%;
			width: 35%;
			.listTitle_top {
				// position: relative;
				display: flex;
				// justify-content: space-between;
				align-items: center;
				.top_right {
					margin-left: 0.12rem;
					font-size: 0.24rem;
					transform: scale(0.83);
					padding: 0 0.05rem;
					border-radius: 0.04rem;
					text-align: center;
				}
				.duo {
					background-color: rgba(50, 191, 136, 0.09);
					color: #32BF88;
				}
				.kong {
					background-color: rgba(245, 70, 93, 0.09);
					color: #F5465D;
				}
			}
			.listName {
				font-size: 0.28rem;
				color: #333333;
				font-family: PingFangSC-Medium;
				font-weight: bold;
			}
			.listName1 {
				font-size: 0.28rem;
				color: #333333;
				font-family: PingFangSC-Medium;
				font-weight: bold;
			}

			.listName_right {
				font-size: 0.12rem;
				font-weight: bold;
				color: #999999;
				padding-left: 0.05rem;
			}

			.listTitle_bottom {
				padding: 0 0.2rem 0.1rem 0;
				display: flex;
				height: 0.5rem;
				align-items: center;
				// justify-content: space-between;

				.new_price {
					color: #6f7989;
					white-space: nowrap;
					line-height: 0.5rem;
					font-size: 0.24rem;
					// font-weight: bold;
				}

				.new_price_red {
					color: #f5465d;
				}

				.new_price_green {
					color: #32bf88;
				}
			}

			.listType {
				height: 0.3;
				line-height: 0.3rem;
				font-size: 0.24rem;
				color: #5eacff;
				border-radius: 0.04rem;
				background-color: #f7faff;
				// padding: 1px 2px;
				margin-left: 0.1rem;
				transform: scale(0.83);
			}
			.color_madin {
				white-space: nowrap;
				border: 0.01rem solid #6f7989;
				color: #6f7989;
				padding: 2px;
			}
			.color_dibu {
				border: 1px solid #00bff1;
				color: #00bff1;
			}
			.color_hailang {
				border: 1px solid #5600f8;
				color: #5600f8;
			}
		}

		.listDesc {
			// flex: 1;
			width: 16%;
			// margin-right: 0.2rem;
			text-align: left;
			white-space: nowrap;

			span {
				font-size: 0.28rem;
				font-weight: 500;
				color: #333;
				// font-weight: bold;
				// margin-left: 0.06rem;
				// letter-spacing: 1px;
			}
			// span.red {
			//     color: #CF4E65;
			// }

			// span.green {
			//     color: #07AD91;
			// }
		}

		.fuying {
			color: #333333;
			font-size: 0.28rem;
			// margin-left: 0.25rem;
			// margin-left: 0.05rem;
			font-weight: 500;
			text-align: right;
			// margin-right: 0.4rem;
		}

		.fuying_dian {
			// padding-top: 0.1rem;
			// margin-left: 0.05rem;
			font-size: 0.24rem;
			// margin-right: 0.4rem;
			text-align: right;
		}
		.fuying_dian.red {
			color: #f5465d;
		}
		.fuying_dian.green {
			color: #32bf88;
		}
		.listfuyin {
			// flex: 1;
			width: 17%;
			// margin-left: 0.2rem;
			text-align: center;
			// margin-right: 0.35rem;
		}

		.listRatio {
			width: 18%;
			margin-left: 0.4rem;
			border-radius: 0.08rem !important;
			// width: 1.6rem;
		}
		.listRatio1 {
			font-size: 0.24rem;
			font-weight: bold;
			color: #fff;
			padding: 0.12rem;
			text-align: center;
			font-family: PingFangSC-Medium;
		}

		.listRatio1.red {
			background: #f5465d;
			border-radius: 6px;
		}

		.listRatio1.green {
			background: #32bf88;
			border-radius: 6px;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;