























.main{
  height: 32px;
  border-radius: 8px;
  border-color: #6F7989;
  border: 1px solid #6F7989;
  position: relative;
  padding-left: 12px;
  display: flex;
  align-items: center;
  input{
    border:none;
    font-size: 12px;
    margin: 5px;
    flex:1
  }
  .btn{
    height: 32px;
    border-radius: 0 8px 8px 0;
    background-color: #6F7989;
    line-height: 32px;
    color: #fff;
    padding: 0 12px 0 17px
  }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;