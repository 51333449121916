.menuList {
  font-family: PingFang SC;
  width: 25%;
  color: #666666;
  font-size: 0.24rem;
  text-align: center;
  margin-bottom: 0.15rem;
  text-transform: capitalize;
}
.menuList .icon {
  width: 0.64rem;
  height: 0.64rem;
  margin: 0 auto;
  margin-bottom: 0.08rem;
}
.menuList .icon img {
  display: block;
  width: 100%;
}
