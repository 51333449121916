.container {
  background-color: #FFFFFF !important;
  color: #999999;
  font-size: 0.24rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
img {
  height: 1.8rem;
  margin-bottom: 0.2rem;
}
