.asideLeft {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.asideLeft .asideBg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(4, 4, 15, 0.4);
}
.asideLeft .asideCon {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 6.15rem;
  padding: 0 0.3rem 0.3rem;
  background: #FFFFFF url(../assets/imgs/aside_bg.png) no-repeat center top;
  background-size: 100% auto;
  overflow-y: auto;
}
.asideLeft .backBtn {
  width: 0.6rem;
  height: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userInfo {
  padding: 0.2rem 0.25rem;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 0.12rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}
.userInfo .avatar {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.25rem;
}
.userInfo .avatar .userHead {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.userInfo .avatar .level {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -0.2rem;
}
.userInfo .avatar .level img {
  width: 0.4rem;
  height: 0.4rem;
  display: block;
  margin-right: -0.25rem;
  position: relative;
  z-index: 2;
}
.userInfo .avatar .level span {
  color: #FFFFFF;
  font-size: 0.12rem;
  width: 1.2rem;
  height: 0.24rem;
  line-height: 0.24rem;
  padding-left: 0.3rem;
  background: linear-gradient(139deg, #BBDDFF 0%, #AFC2FF 100%);
  border: 1px solid #FFFFFF;
  border-radius: 0.16rem;
  display: block;
  position: relative;
  z-index: 1;
}
.userInfo .nike {
  color: #373737;
  font-size: 0.36rem;
  line-height: 0.5rem;
  margin-bottom: 0.08rem;
}
.userInfo .deadline {
  color: #666666;
  font-size: 0.24rem;
}
.adPoster {
  height: 1.35rem;
  margin-bottom: 0.5rem;
}
.adPoster img {
  display: block;
  width: 100%;
  height: 100%;
}
.logOutBtn {
  color: #FFFFFF;
  font-size: 0.32rem;
  height: 0.88rem;
  line-height: 0.88rem;
  text-align: center;
  background: #D1D1D6;
  border-radius: 0.12rem;
  margin-top: 1rem;
}
