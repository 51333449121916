.rankList {
  color: #FFFFFF;
  width: 2.1rem;
}
.rankList .cont {
  background: #90C4FF url(../../../assets/imgs/index/blue.png) no-repeat center top;
  background-size: 100% 100%;
  border-radius: 0.12rem;
}
.rankList .crown {
  width: 0.58rem;
  height: 0.36rem;
  display: block;
  margin: 0 auto -0.05rem;
}
.rankList .hd {
  font-size: 0.2rem;
  height: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}
.rankList .hd .avatar {
  width: 0.48rem;
  height: 0.48rem;
  border-radius: 50%;
  display: block;
  margin-right: 0.12rem;
}
.rankList .bd {
  font-size: 0.24rem;
  padding: 0.15rem 0.1rem;
  height: 1.56rem;
}
.rankList .bd span {
  font-family: Bahnschrift;
}
.rankList.firstOne {
  width: 2.3rem;
}
.rankList.firstOne .crown {
  width: 0.72rem;
  height: 0.46rem;
}
.rankList.firstOne .hd {
  height: 0.75rem;
}
.rankList.firstOne .bd {
  height: 2.24rem;
}
.rankList.firstOne .avatar {
  width: 0.6rem;
  height: 0.6rem;
}
