








































.rankList {
	color: #FFFFFF;
	width: 2.1rem;
	.cont {
		background: #90C4FF url(../../../assets/imgs/index/blue.png) no-repeat center top;
		background-size: 100% 100%;
		border-radius: .12rem;
	}
	.crown {
		width: .58rem;
		height: .36rem;
		display: block;
		margin: 0 auto -.05rem;
	}
	.hd {
		font-size: .2rem;
		height: .6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid rgba(255, 255, 255, .16);
		.avatar {
			width: .48rem;
			height: .48rem;
			border-radius: 50%;
			display: block;
			margin-right: .12rem;
		}
	}
	.bd {
		font-size: .24rem;
		padding: .15rem .1rem;
		height: 1.56rem;
		span {
			font-family: Bahnschrift;
		}
	}
	&.firstOne {
		width: 2.3rem;
		.crown {
			width: .72rem;
			height: .46rem;
		}
		.hd {
			height: .75rem;
		}
		.bd {
			height: 2.24rem;
		}
		.avatar {
			width: .6rem;
			height: .6rem;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;