




















.container {
	background-color: #FFFFFF!important;
	color: #999999;
	font-size: .24rem;
	height: 3rem;
	// text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
img {
	height: 1.8rem;
	margin-bottom: .2rem;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;